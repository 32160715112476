<template>
  <div :class="[`v-pages`, classBase]">
    <div class="container">
      <div class="v-pages_logo">
        <img src="@/assets/img/logo_game.png" />
      </div>

      <div class="v-pages_heading">
        <span class="v-pages_title">
          {{ title }}
        </span>

        <span class="v-pages_description">
          {{ description }}
        </span>
      </div>

      <div class="v-pages_sub" v-if="showSub">
        <div class="v-pages_subWrap">
          <span class="v-pages_subDescription">{{ subDescription }}</span>
          <div class="v-pages_subBtn">
            <c-button
              label="Iscriviti"
              :href="linkSubscription"
              @click="openSubscription"
              classBase="c-button-blue c-button-w160px"
            />
          </div>
        </div>
      </div>

      <div class="v-pages_btActions">
        <span class="v-pages_btnStart">
          <c-button
            v-if="showRestartButton"
            v-bind="btnRestart"
            @click="repeatPlay"
          />
          <c-button
            v-bind="btnStart"
            @click="nextPlay"
            :loading="dataLoading"
          />
        </span>

        <a :href="linkRules.href" class="v-pages_linkRules">
          {{ linkRules.label }}
        </a>
      </div>

      <!-- CTA Block, external links -->
      <div class="v-pages_cta"  v-if="!showSub">
        <span class="v-pages_ctaDescription">{{ cta.description }}</span>
        <div class="v-pages_ctaButtons">
          <c-button v-for="(btn, i) in cta.buttons" :key="i" v-bind="btn" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Rappresenta concettualmente 3 pagine: la home (showSub==undefined), la pagina di vittoria (showSub==true) e la pagina di gameover (showSub==false)
 */
import { Analytic } from '@/analytics';
import cButton from '@components/c-button/c-button.vue';
export default {
  name: 'v-pages',
  components: {
    cButton
  },
  props: {
    classBase: String,
    showSub: Boolean,
    dataLoading: Boolean,
    linkRules: {
      default: {
        href: 'regolamento.pdf',
        label: 'Regolamento'
      }
    },
    title: {
      default: 'Game online'
    },
    description: {
      default:
        'Indovina le 15 parole entro 2 minuti e 30 secondi spostando le lettere. '
    },
    linkSubscription: {
      default: 'https://www.rsi.ch/la-rsi/iscrizioni/solo1lettera-concorrente/'
    }
  },
  computed: {
    showRestartButton() {
      return this.showSub === false;
    },
    btnStart() {
      const label = !this.showRestartButton ? 'Gioca' : 'Nuova partita';
      const classBase = !this.showRestartButton ? 'c-button-w160px' : '';
      return {
        label,
        classBase
      };
    },
    btnRestart() {
      return {
        label: 'Riprova',
        classBase: 'u-push-btm-third c-button-blue'
      };
    }
  },
  data() {
    return {
      subDescription: 'Perché non partecipi al quiz televisivo?',
      cta: {
        description:
          'Perché non partecipi al quiz televisivo? Oppure guarda le puntate.',
        buttons: [
          {
            label: 'Iscriviti',
            href: 'https://www.rsi.ch/la-rsi/iscrizioni/solo1lettera-concorrente/',
            classBase: 'c-button-blue',
            target: '_self'
          },
          {
            label: 'Guarda',
            href: 'https://www.rsi.ch/play/tv/programma/solo1lettera?id=704142',
            classBase: 'c-button-blue',
            target: '_self'
          }
        ]
      }
    };
  },
  emits: ['start'],
  methods: {
    nextPlay() {
      this.$emit('start');
    },
    repeatPlay() {
      this.$emit('start', { repeat: true });
    },
    openSubscription() {
      Analytic.event('gamewon', 'signup', '');
    }
  }
};
</script>

<style lang="scss" scoped>
.v-pages {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  min-height: 100%;
  padding-bottom: $space-double;
}

// Logo
//===============================
.v-pages_logo {
  display: flex;
  justify-content: center;
  width: 30rem;
  margin: 0 auto;
  padding-top: $space-double;
}

// Heading
//===============================
.v-pages_heading {
  margin-top: $space-oneAndHalf;
}

// Title
.v-pages_title {
  display: block;
  font-size: 2.4rem;
  font-weight: bold;
  text-align: center;
  color: $white;
}

// Description
.v-pages_description {
  display: block;
  margin-top: $space;
  font-size: 2rem;
  text-align: center;
  color: $white;
}

// Button subs
//===============================
.v-pages_subWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: $space-double auto 0 auto;
  max-width: 40.6rem;
  padding: $space;
  background-color: rgba(38, 39, 109, 0.2);
}

.v-pages_subDescription {
  display: block;
  font-size: 1.8rem;
  text-align: center;
  color: $white;
}

.v-pages_subBtn {
  margin-top: $space;
}

// Buttons
//===============================
.v-pages_btActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: $space-double;
}

.v-pages_btnStart {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
}

.v-pages_linkRules {
  color: $white;
  margin-top: $space;
  padding-bottom: 0.2rem;
  border-bottom: 1px dashed $white;
}

.v-pages_cta {
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: rgba($color: #26276d, $alpha: 0.2);
  color: white;
  margin-top: $space-double;
  padding: $space;
  @media (max-width: 600px) {
    margin-top: $space;
    width: 100%;
  }
  .v-pages_ctaButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: $space;
    @media (max-width: 420px) {
      flex-direction: column;
      margin-top: $space/2;
    }
    .c-button {
      margin: 0 1rem;
      @media (max-width: 420px) {
        margin: 1rem 0;
      }
    }
  }
}
</style>
